<template>
  <div id="new-features">
    <div class="d-flex flex-row justify-content-between header">
      <div class="d-flex flex-row align-items-end">
        <p class="title">Novas Aplicações</p>
      </div>
    </div>

    <div class="cards__container">
      <b-card
        v-for="feature in applicationList"
        :key="feature.title"
        class="feature-card"
      >
        <div class="feature-card__header">
          <span class="feature__type">{{ feature.segment }}</span>
          <span class="new">Novidade</span>
        </div>

        <NFe class="icon" v-if="feature.icon == 'nfe'" />
        <NFe class="icon" v-if="feature.icon == 'nfe2'" />

        <p class="title__l">{{ feature.name }}</p>
        <p class="feature__description">
          {{ feature.description }}
        </p>

        <div class="text-center">
          <b-button
            class="feature-card__button"
            @click="showPlansOptions(feature.id)"
          >
            Ver planos
          </b-button>
        </div>
      </b-card>
    </div>

    <NewFeraturesPlans :planSelectedData="planSelectedData" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'NewFeatures',
  components: {
    NFe: () => import('@/assets/icons/nfe.svg'),
    NewFeraturesPlans: () =>
      import('@/modules/newFeatures/modals/NewFeaturesPlans')
  },
  data() {
    return {
      applicationList: [
        {
          id: 1,
          segment: 'financeiro',
          icon: 'nfe',
          name: 'Nota Fiscal',
          description: 'Emita e administre suas notas fiscais de forma simples'
        }
      ],
      planSelectedData: []
    }
  },
  methods: {
    async showPlansOptions() {
      this.$bvModal.show('plan_options')
    },
    resetCookie() {
      Cookies.set('showNewFeatureModal', true)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main';
#new-features {
  height: 100%;
  padding: 30px 24px;
  text-align: left;

  .header {
    margin-bottom: 24px;

    .title {
      font-family: 'Red Hat Display';
      font-weight: 700;
      font-size: 24px;
      color: var(--dark-blue);
      margin-bottom: 0;
    }
  }

  .cards__container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem 0;
  }

  .feature-card {
    width: 270px;
    margin-right: 1.2rem;
    border-radius: $border-radius-base;
    box-shadow: 0px 4px 10px -8px #0c1d5933;

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      // para as divs filhas ocuparem todo espaço disponível e o botão ficar alinhado abaixo
      > div {
        height: 100%;
      }
    }

    .feature-card__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .feature__type {
      font-size: 12px;
      font-weight: 600;
      font-family: 'Nunito Sans';
      color: var(--neutral-500);
    }

    .new {
      padding: 0 8px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 22px;
      color: var(--neutral-70);
      font-family: 'Nunito Sans';
      background: var(--blue-600);
    }

    .title__l {
      margin-top: 6rem;
      text-align: center;
    }

    .icon {
      position: absolute;
      top: 60px;
    }

    .feature__description {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      font-family: 'Nunito Sans';
      color: var(--neutral-700);
    }

    .feature-card__button {
      border: none;
      font-weight: 700;
      border-radius: $border-radius-base;
      background: var(--orange);
    }
  }
}
</style>
